
import axios, { AxiosError } from 'axios';
import { defineComponent, ref, watch, onBeforeMount, Ref } from 'vue';
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampTable,
  CampTableTd,
  CampTableTh,
  CampEmptyListFeedbackV2,
  CampFooterPaginationControl,
CampBtnGenerateExcel
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import moment from 'moment';
import { ptBr } from 'element-plus/es/locale';
import { ICompanyData } from '@/store/CompaniesStore';
import { ICompanyResponse } from '../Companies/ListPage.vue';
import ModalInfoLog, { ILog } from '@/views/Developer/components/ModalInfoLog.vue';
import { campGoToTopByScrollMode, campHandleCamelCaseToTitleCase } from '@/composables/Helpers';

export type TOperationType = 'auto' | 'semi-auto' | 'custom'

export interface ILogPushNotification extends ILog {
  status_header: number
  operation_type: TOperationType
  recipient_company_id: null | number
  recipient_campany_name: string | null
  recipient_store_id: null | number
  recipient_store_name: string | null
  user_id: null | number
  user_name: string | null
  send_to: string
}

interface ICompetitorData {
  name: string
  cpf: string
  id: string
}

interface ILogResponse {
  data: {
    data: {
      data: ILogPushNotification[],
      current_page: number,
      from: number,
      last_page: number,
      per_page: number,
      to: number,
      total: number,
    },
    error: boolean,
    message: string,
    errorCode: string,
  }
}

interface IAvailableJobsResponse {
  data: {
    data: IAvailableJobsData[],
    error: boolean,
    message: string,
    errorCode: string,
  }
}

interface IAvailableJobsData {
  jobName: string
  frequency: string
  id: number
  label: string
  message: string
  selected: boolean
  title: string
}

interface IAvailableJobsOption {
  label: string
  key: number | string
}

type TStatusCodeType = "information" | "successful" | "redirection" | "client-error" | "server-error"

type TBodyParams = {
  page?: number
  oldest?: boolean
  statusCodeType?: TStatusCodeType
  companyIdList?: number[]
  storeIdList?: number[]
  jobIdList?: number[]
  rangeDate?: Date
  startDate?: Date
  endDate?: Date
}

type TParamOption = {
  key: string | number;
  label: string;
  value?: string | number;
}

const OLDES_LIST: TParamOption[] = [
  { key: 0, label: "Mais recente" },
  { key: 1, label: "Mais antigo" },
]

const FEEDBACK_LIST: TParamOption[] = [
  { key: "information", label: "Status 1XX" },
  { key: "successful", label: "Status 2XX" },
  { key: "redirection", label: "Status 3XX" },
  { key: "client-error", label: "Status 4XX" },
  { key: "server-error", label: "Status 5XX" },
]

const handlerError = (error: AxiosError | unknown, showTimeAlert: Function) => {
  if(error instanceof AxiosError)
    showTimeAlert(error.response?.data.message, "error")
  else
    showTimeAlert("Algo deu errado!", "error")
}

const handleJoinObjectList = (
  logList: ILogPushNotification[],
  availableJobsList: IAvailableJobsOption[],
  competitorList: ICompetitorData[]
) => {
  return logList.map(log => {
    const competitor = competitorList.find(item => log.competitorID === item.id)
    return {
      ...log,
      label: availableJobsList.find(job => job.key === log.id_job)?.label || "-",
      competitorName: competitor?.name || "-",
      competitorCPF: competitor?.cpf || "-"
    }
  })
}

const handleFetchJoinObjectList = async(logPushNotificationList: ILogPushNotification[], availableJobsList:IAvailableJobsOption[]) => {
  let competitorList: ICompetitorData[] = []

  const logsResult = logPushNotificationList.map(log => {
    if(typeof log.return === "string") {
      const jsonParsed = JSON.parse(log.return)[0]
      return { ...log, status_header: jsonParsed.statusCode, competitorID: jsonParsed.competitors_id }
    }
    return { ...log, status_header: null, competitorID: null }
  })

  if(Array.isArray(logsResult) && logsResult.length > 0) {
    const competitorIDs: string[] = logsResult
      .map(log => log.competitorID)
      .filter((value, index, self) => self.indexOf(value) === index)

    if(competitorIDs.length > 0) {
      const { data }:{ data: ICompetitorData[] } = (await axios.post('/api/generateCompetitorListByIDs', {
        competitorIDs,
        excludedToo: true,
      })).data
      competitorList = data
    }
  }

  return handleJoinObjectList(logsResult, availableJobsList, competitorList)
}

const handleUpdateLogListRender = (logList: Ref<ILogPushNotification[] | null>, logListRender: Ref<ILogPushNotification[] | null>,) => {
  if(Array.isArray(logList.value) && logList.value.length > 0)
    logListRender.value = logList.value.map(log => ({ ...log }))
}

const handleFilterObjectsByKeyword = (
  logList: Ref<ILogPushNotification[] | null>,
  logListRender: Ref<ILogPushNotification[] | null>,
  keywordSearch: Ref<string>
): boolean => {
  if(!logList.value)
    return true
  logListRender.value = logList.value.filter((item) => {
    for (const key in item) {
      const propValue = item[key];
      if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase()))
        return true;
      if (typeof propValue === 'number' && keywordSearch.toString() === propValue.toString())
        return true;
    }
    return false;
  });
  return false
}

const handleBodyParams = (bodyParamsModel: Ref<TBodyParams>, bodyParamsCompleted: Ref<TBodyParams>) => {
  bodyParamsCompleted.value = {}

  if(Array.isArray(bodyParamsModel.value.companyIdList) && bodyParamsModel.value.companyIdList.length > 0)
    bodyParamsCompleted.value.companyIdList = [ ...bodyParamsModel.value.companyIdList ]

  if(Array.isArray(bodyParamsModel.value.storeIdList) && bodyParamsModel.value.storeIdList.length > 0)
    bodyParamsCompleted.value.storeIdList = [ ...bodyParamsModel.value.storeIdList ]

  if(Array.isArray(bodyParamsModel.value.jobIdList) && bodyParamsModel.value.jobIdList.length > 0)
    bodyParamsCompleted.value.jobIdList = [ ...bodyParamsModel.value.jobIdList ]

  if(Array.isArray(bodyParamsModel.value.rangeDate) && bodyParamsModel.value.rangeDate.length > 1) {
    bodyParamsCompleted.value.startDate = bodyParamsModel.value.rangeDate[0]
    bodyParamsCompleted.value.endDate = bodyParamsModel.value.rangeDate[1]
  }

  if(typeof bodyParamsModel.value.oldest === "number")
    bodyParamsCompleted.value.oldest = bodyParamsModel.value.oldest

  if(bodyParamsModel.value.statusCodeType)
    bodyParamsCompleted.value.statusCodeType = bodyParamsModel.value.statusCodeType
}

export default defineComponent({
  name: "PushNotificationLogList",
  components: {
    CampHeader,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampEmptyListFeedbackV2,
    ModalInfoLog,
    CampFooterPaginationControl,
    CampBtnGenerateExcel
  },
  setup() {
    const { showTimeAlert } = useAlert()
    const loaderStore = useLoaderStore()
    const isLoading = ref(true)
    const indexCurrentPage = ref(1)
    const totalPage = ref(1)
    const companyList = ref<ICompanyData[] | null>(null)
    const storeList = ref<ICompanyData[] | null>(null)
    const availableJobsList = ref<IAvailableJobsOption[] | null>(null)
    const logList = ref<ILogPushNotification[] | null>(null)
    const logListRender = ref<ILogPushNotification[] | null>(null)
    const modalInfoLog = ref<{ log: ILog | null, toggle: boolean }>({
      log: null,
      toggle: true
    })
    const keywordSearch = ref("")
    const bodyParamsModel = ref<TBodyParams>({})
    const bodyParamsCompleted= ref<TBodyParams>({})

    /** Functions */
    async function getDatas() {
      try {
        isLoading.value = true
        type TResponseList = [ ILogResponse, ICompanyResponse, ICompanyResponse, IAvailableJobsResponse ]
        const [ logsResponse, companiesResponse, storesResponse, availableJobsResponse ]: TResponseList = await Promise.all([
          axios.post('/api/generateLogPushNotificationList'),
          axios.get('/api/getAllCompanies'),
          axios.get('/api/getAllStores'),
          axios.get('/api/getJobAssignments'),
        ]);
        availableJobsList.value = availableJobsResponse.data.data.map(el => ({ key: el.id, label: campHandleCamelCaseToTitleCase(el.jobName)  }))
        logList.value = await handleFetchJoinObjectList(logsResponse?.data?.data?.data, availableJobsList.value)
        console.log(logList.value)
        companyList.value = companiesResponse.data.data.map(el => ({ ...el })) || null
        storeList.value = storesResponse.data.data.map(el => ({ ...el })) || null
        indexCurrentPage.value = 1
        totalPage.value = logsResponse.data.data.last_page
      } catch (error) {
        handlerError(error, showTimeAlert)
      } finally {
        isLoading.value = false
      }
    }

    async function handleSearchLogList() {
      try {
        indexCurrentPage.value = 1
        handleBodyParams(bodyParamsModel, bodyParamsCompleted)
        isLoading.value = true
        const response: ILogResponse = await axios.post(
          "/api/generateLogPushNotificationList",
          { ...(JSON.parse(JSON.stringify(bodyParamsCompleted.value))), page: indexCurrentPage.value }
        )
        const { data } = response.data
        logList.value = await handleFetchJoinObjectList(data.data, availableJobsList.value || [])
        totalPage.value = response.data.data.last_page
      } catch (error) {
        handlerError(error, showTimeAlert)
      } finally {
        isLoading.value = false
      }
    }

    async function handlePaginationRendering(pageID: number) {
      try {
        loaderStore.open()
        indexCurrentPage.value = pageID
        const response: ILogResponse = await axios.post(
          "/api/generateLogPushNotificationList",
          { ...(JSON.parse(JSON.stringify(bodyParamsCompleted.value))), page: indexCurrentPage.value }
        )
        const { data } = response.data
        logList.value = await handleFetchJoinObjectList(data.data, availableJobsList.value || [])
        campGoToTopByScrollMode(false)
      } catch (error) {
        handlerError(error, showTimeAlert)
      } finally {
        loaderStore.close()
      }
    }

    const handleModalInfoLog = (el: ILog) => {
      modalInfoLog.value.toggle = !modalInfoLog.value.toggle
      modalInfoLog.value.log = { ...el }
    }

    /** Life Cycles */
    onBeforeMount(() => getDatas())

    watch(() => logList.value, () => {
      handleUpdateLogListRender(logList, logListRender)
      handleFilterObjectsByKeyword(logList, logListRender, keywordSearch)
    }, { deep: true })

    watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword(logList, logListRender, keywordSearch))

    return {
      moment,
      ptBr,
      isLoading,
      keywordSearch,
      logListRender,
      companyList,
      storeList,
      availableJobsList,
      bodyParamsModel,
      OLDES_LIST,
      FEEDBACK_LIST,
      handleSearchLogList,
      handlePaginationRendering,
      indexCurrentPage,
      totalPage,
      handleModalInfoLog,
      modalInfoLog,
    }
  }
})
